import React, { createContext, useContext, useState } from 'react'

type State = {
  isPageNavigationDisabled: boolean
  setIsPageNavigationDisabled: (value: boolean) => void
}
const GlobalContext = createContext<State>({} as State)

export const GlobalContextProvider = ({ children }) => {
  const [isPageNavigationDisabled, setIsPageNavigationDisabled] = useState(false)
  const value = { isPageNavigationDisabled, setIsPageNavigationDisabled }
  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  )
}

export const useGlobalContext = () => useContext(GlobalContext)

