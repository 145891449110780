import { WrapPageElementBrowserArgs } from 'gatsby'
import React from 'react'
import { ThemeProvider } from 'styled-components'
import Layout from './src/components/Layout'
import ContactDialogContextProvider from './src/context/ContactDialogContextProvider'
import { AnalyticsContextProvider } from './src/context/analytics/analytics.context'
import { GlobalStyle, theme } from './src/theme/global-style'
import { GlobalContextProvider } from './src/context/GlobalContext'

export const wrapPage = ({ element, props }: WrapPageElementBrowserArgs) => (
  <>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <GlobalContextProvider>
        <AnalyticsContextProvider>
          <ContactDialogContextProvider>
            <Layout {...props}>{element}</Layout>
          </ContactDialogContextProvider>
        </AnalyticsContextProvider>
      </GlobalContextProvider>
    </ThemeProvider>
  </>
)
